'use strict';

(function() {
  class ComponentMapCtrl {
    constructor($scope, $window, appConfig, Util) {
      this.bladePositionMap = {
        'A': 1,
        'B': 2,
        'C': 3
      };
      this.Util = Util;
      this.components = _.sortBy($scope.components, (component)=>{
        return this.bladePositionMap[component.blade.position]?this.bladePositionMap[component.blade.position]:component.blade.position;
      });
      this.turbine = $scope.turbine;
      this.disableNavigation = $scope.disableNavigation;
      this.loading = true;
      this.graphWidth = 250;
      this.graphHeight = 250;
      this.sharedLevel = localStorage.getItem('sharedLevel');
      var totalCatCount;

      let w = angular.element($window);
      $scope.getWindowDimensions = function() {
        return {
          'h': w.height(),
          'w': w.width()
        };
      };

      $scope.$watch($scope.getWindowDimensions, (newValue, oldValue) => {
        if (newValue.w < 768) {
          this.graphWidth = newValue.w - 40;
          this.graphHeight = 250;
        } else if (newValue.w >= 768 && newValue.w <= 992) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 992 && newValue.w <= 1200) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else if (newValue.w > 1200 && newValue.w <= 1360) {
          this.graphWidth = 173;
          this.graphHeight = 250;
        } else {
          this.graphWidth = 212;
          this.graphHeight = 250;
        }

        this.components.forEach(component=> {
          var totalCatCount = component.findings.filter(finding=>finding.category >= 1 && finding.category <=5).length;
          /* Chart options */
          component.options = {
            chart: {
              type: 'pieChart',
              width: this.graphWidth,
              height: this.graphHeight,
              donut: true,
              title: totalCatCount,
              x: function(d) {
                return d.key;
              },
              y: function(d) {
                return d.y;
              },
              showLegend: true,
              showLabels: true,
              showValues: true,
              valueFormat: function(d) {
                return d3.format(',.0f')(d);
              },
              labelType: function(d) {
                return d.value
              },
              color: function(d, i) {
                return (d && d.color);
              },
              duration: 500
            }
          };
        });
      }, true);

      w.bind('resize', function() {
        $scope.$apply();
      });

      $scope.$watchCollection('components', (newVal, oldVal)=> {

        if (newVal != oldVal) {
          this.loading = false;
          this.components = newVal;
          // console.log('new components: ', this.components);
          // debugger
          this.components.forEach(component=> {
            var totalCatCount = component.findings.filter(finding=>finding.category >= 1 && finding.category <=5).length;
            /* Chart options */
            component.options = {
              chart: {
                type: 'pieChart',
                width: this.graphWidth,
                height: this.graphHeight,
                donut: true,
                title: totalCatCount,
                x: function(d) {
                  return d.key;
                },
                y: function(d) {
                  return d.y;
                },
                showLegend: true,
                showLabels: true,
                showValues: true,
                valueFormat: function(d) {
                  return d3.format(',.0f')(d);
                },
                labelType: function(d) {
                  return d.value
                },
                color: function(d, i) {
                  return (d && d.color);
                },
                duration: 500
              }
            };
            component._data = [
              {
                key: "Cat 0 : No Risk",
                y: component.findings.filter(finding=>finding.category == 0).length,
                color: appConfig.colors.cat0
              },
              {
                key: "Cat 1 : Low - No action required",
                y: component.findings.filter(finding=>finding.category == 1).length,
                color: appConfig.colors.cat1
              },
              {
                key: "Cat 2 : Medium - Monitor during inspection schedule/repair if repairing other findings",
                y: component.findings.filter(finding=>finding.category == 2).length,
                color: appConfig.colors.cat2
              },
              {
                key: "Cat 3 : Serious - Reinspect/Repair within 6 months",
                y: component.findings.filter(finding=>finding.category == 3).length,
                color: appConfig.colors.cat3
              },
              {
                key: "Cat 4 : Major - Reinspect/Repair within 3 months",
                y: component.findings.filter(finding=>finding.category == 4).length,
                color: appConfig.colors.cat4
              },
              {
                key: "Cat 5 : Critical - STOP turbine investigate/repair/replace immediately",
                y: component.findings.filter(finding=>finding.category == 5).length,
                color: appConfig.colors.cat5
              }
            ];
          })

          this.components = _.sortBy(this.components, (component)=>{
            return this.bladePositionMap[component.blade.position]?this.bladePositionMap[component.blade.position]:component.blade.position;
          });
        }
      });
    }

    getBladePosition(position) {
      return this.bladePositionMap[position]?this.bladePositionMap[position]:position;
    }

    getCavityInspectionTypeId(type) {
      return this.Util.getCavityInspectionTypeId(type);
    }

    getClass(finding) {
      // console.log('ComponentMapCtrl', finding);
      if(finding)
        return this.Util.getFindingClass(finding);
    }
  }

  angular.module('windmanagerApp')
    .controller('ComponentMapCtrl', ComponentMapCtrl);
})();
